<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Ajuda': 'Ajuda',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Ajuda': 'Ajuda',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      faqs: null
    };
  },
  methods: {
    getList() {
      api
        .get('faq')
        .then(response => {
          if (response.data.status == 'success') {
            this.faqs = response.data.list
          } else {
            this.faqs = []
          }
        })
        .catch(error => {
          if (error) {
            this.faqs = []
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Ajuda') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
            <div class="text-center" v-if="!faqs">
              <b-spinner variant="default" role="status"></b-spinner>
            </div>

            <div class="text-center" v-else-if="faqs && faqs.length == 0">
              <i class="far fa-sad-cry font-size-24"></i><br>
              nenhum registro encontrado
            </div>

            <div v-else class="table-responsive">
              <b-card no-body class="mb-1 shadow-none" e v-for="(faq, index) in faqs" :key="index">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a v-b-toggle="'accordion-' + faq.id" class="text-dark" href="javascript: void(0);">{{ faq.question }}</a>
                  </h6>
                </b-card-header>
                <b-collapse :id="'accordion-' + faq.id" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div v-html="faq.answer"></div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>